import AngledBracket from './icons/AngledBracket.svg';
import React, { FC } from 'react';
import Link from 'next/link';

interface Props {
  text: string;
  link: string;
}

const AngleButton: FC<Props> = ({ text, link }) => {
  return (
    <Link href={link}>
      <a>
        <div className='flex cursor-pointer'>
          <div className='flex text-lg font-semibold text-normal text-primary hover:text-primary-hover'>
            {text}
            <div className='ml-2 mt-0.5'>
              <AngledBracket className='stroke-current stroke-3' />
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default AngleButton;
