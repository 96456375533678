import { GetStaticProps, NextPage } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { getClient } from '../lib/sanity.server';
import { groq } from 'next-sanity';
import Head from 'next/head';
import OrangeDivider from '../components/OrangeDivider';
import FeaturesTable from '../components/home/FeaturesTable';
import LogoGrid from '../components/home/LogoGrid';
import FlamePulse from '../components/home/FlamePulse';
import styles from '../components/home/home.module.css';
import { Props } from '../components/WistiaEmbed';
import AngleButton from '../components/AngleButton';
import AngledBracket from '../components/icons/AngledBracket.svg';

interface StaticProps {
  title: string;
  totalSalvationCount: number;
  seo: {
    description: string;
    imageUrl: string;
  };
}

export const getStaticProps: GetStaticProps<StaticProps> = async ({ preview = false }) => {
  const homePageQuery = groq`
    *[_type == "singletonHome"][0] {
      title,
      totalSalvationCount,
      seo {
        description,
        "imageUrl": openGraphImage.asset->url
      }
    }
  `;

  const homePageData = await getClient(preview).fetch<StaticProps>(homePageQuery);

  return {
    props: {
      ...homePageData,
    },
  };
};

const WistiaEmbed = dynamic<Props>(
  () => import('../components/WistiaEmbed').then(mod => mod.WistiaEmbed),
  { ssr: false }
);

const Index: NextPage<StaticProps> = ({ title, totalSalvationCount, seo }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='title' content={title} />
        <meta name='description' content={seo ? seo.description : ''} />
        <meta property='og:image' content={seo ? seo.imageUrl : ''} />
      </Head>
      <main>
        <div className='h-auto max-w-6xl pb-24 mx-auto text-center sm:mb-8'>
          <div className='absolute top-0 flex justify-center w-full mx-auto -mx-4 overflow-hidden pointer-events-none sm:-mx-8 -z-1'>
            <div className='sm:hidden'>
              <Image
                src='/images/home/hero_small.jpg'
                alt='Watching church online'
                layout='fixed'
                width={640}
                height={760}
                quality={100}
              />
            </div>
            <div className='hidden sm:block lg:hidden'>
              <Image
                src='/images/home/hero_medium.jpg'
                alt='Watching church online'
                layout='fixed'
                width={1024}
                height={800}
                quality={100}
              />
            </div>
          </div>
          <div className='w-full sm:grid sm:grid-cols-12'>
            <div className='sm:col-start-3 lg:col-start-7 sm:col-span-8 lg:col-span-6'>
              <div
                className={`relative lg:flex lg:flex-col lg:justify-center ${styles['hero-height']} pt-80 lg:pt-0 lg:pb-16`}
              >
                <div className='absolute lg:static lg:text-left bottom-24 sm:bottom-32'>
                  <h1 className={`text-white ${styles['title-font']}`}>Take Your Church Online</h1>
                  <OrangeDivider className={'h-1.5 w-32 my-5 lg:my-6 mx-auto lg:mx-0'} />
                  <h2 className='text-white subhead'>
                    Engage your attenders and lead them to next steps with Church Online Platform.
                  </h2>
                  <div className='flex flex-row justify-center mt-6 lg:justify-start'>
                    <div className='mr-3 sm:hidden'>
                      <Link href='/signup'>
                        <a className='text-white btn btn-lg btn-secondary hover:text-primary-hover border-white-transparent-20'>
                          Sign Up
                        </a>
                      </Link>
                    </div>
                    <Link href='/how-it-works'>
                      <a className='flex items-center ml-3 text-lg font-semibold text-white hover:text-primary-hover lg:text-xl sm:ml-0'>
                        How it works
                        <div className='ml-2 mt-0.5'>
                          <AngledBracket className='stroke-current stroke-3' />
                        </div>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-6xl mx-auto pointer-events-none'>
          <div className='hidden lg:block'>
            <Image
              src='/images/home/hero_large.jpg'
              alt='Watching church online'
              layout='fill'
              objectFit='cover'
              quality={100}
              className='-z-1'
            />
          </div>
        </div>
        <div className='h-auto max-w-6xl mx-auto mb-24 sm:grid sm:grid-cols-12 sm:mb-36 lg:mb-44'>
          <div className='sm:col-start-1 sm:col-end-7 lg:col-end-6 sm:mr-4 sm:z-10'>
            <p className='mb-3 text-lg font-semibold text-brands-chop'>Why Online Ministry</p>
            <h3 className='mb-2 section-header'>Reach people where they are.</h3>
            <p className='mb-4 body-paragraph'>
              Through Church Online Platform, your church can reach right into living rooms,
              offices, communities, and hearts of people no matter where they are in the world ...
              and no matter where they are with God.{' '}
            </p>
          </div>
          <div className={styles['gray-box']}>
            <div className={`${styles['video-spacing']} sm:mb-24 lg:mb-40 sm:ml-4`}>
              <WistiaEmbed assetId={'ober227lxo'} popover />
            </div>
          </div>
          <div className='mt-8 sm:hidden'>
            <WistiaEmbed assetId={'ober227lxo'} popover />
          </div>
        </div>
        <div className='h-auto max-w-6xl mx-auto mb-16 sm:mb-24 lg:mb-28'>
          <div className='sm:flex sm:flex-row-reverse sm:ml-4'>
            <div className='lg:flex lg:flex-col lg:justify-center sm:w-1/2 sm:mt-4 sm:ml-4'>
              <p className='mb-3 text-lg font-semibold text-brands-chop'>Pricing: 100% Free</p>
              <h3 className='mb-2 section-header'>Features built for churches.</h3>
              <p className='mb-2 body-paragraph'>
                Church Online Platform has the unique features you need to interact with attenders,
                build community, and help hurting people find Jesus. And they’re all included
                completely free of charge.
              </p>
              <p className='mb-12 body-paragraph sm:mb-6'>
                You&apos;ll get the Platform, every feature, ongoing support, and upgrades for the
                life of the tool—because eternities are at stake.
              </p>
              <div className='hidden sm:block sm:flex sm:flex-wrap'>
                <div className='w-48 mb-4'>
                  <AngleButton text={'Explore Features'} link={'/features'} />
                </div>
                <div className='w-48'>
                  <Link href='/about'>
                    <a className='flex text-lg font-semibold tracking-wide text-primary hover:text-primary-hover'>
                      How is this free?
                      <div className='ml-1 mt-0.5'>
                        <AngledBracket className='stroke-current stroke-3' />
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <FeaturesTable />
            <div className='flex flex-wrap mt-4 sm:hidden'>
              <div className='w-48 mb-6'>
                <AngleButton text={'Explore Features'} link={'/features'} />
              </div>
              <div className='w-48'>
                <Link href='/about'>
                  <a className='flex text-lg font-semibold tracking-wide text-primary hover:text-primary-hover'>
                    How is this free?
                    <div className='ml-1 mt-0.5'>
                      <AngledBracket className='stroke-current stroke-3' />
                    </div>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='h-auto max-w-6xl mx-auto mb-24 sm:mb-32 lg:mb-40'>
          <div className='sm:flex sm:flex-col sm:items-center sm:mx-auto md:max-w-2xl xl:max-w-none'>
            <OrangeDivider className={'h-1 w-12 mb-4'} />
            <h4 className='mb-6 group-header sm:text-center'>
              Works with your streaming provider.
            </h4>
            <div className='max-w-sm mx-auto md:max-w-lg xl:max-w-none'>
              <LogoGrid />
            </div>
            <p className='mb-2 -mt-3 body-paragraph sm:text-center'>
              Broadcast your live or pre-recorded church service with the streaming provider of your
              choice.
            </p>
            <p className='mb-6 body-paragraph sm:text-center'>
              No matter your budget, there’s a streaming provider option that works seamlessly with
              Church Online Platform.{' '}
            </p>
            <a
              href='https://support.online.church/article/gs26xs0vhm-streaming-providers'
              target='_blank'
              rel='noreferrer'
            >
              <div className='flex cursor-pointer'>
                <div className='flex text-lg font-semibold text-normal text-primary hover:text-primary-hover'>
                  Learn about streaming
                  <div className='ml-2 mt-0.5'>
                    <AngledBracket className='stroke-current stroke-3' />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className='relative w-screen mb-24 bg-black -left-4 sm:-left-8 sm:mb-32 lg:mb-40 -z-1'>
          <div className='absolute flex justify-center w-full -z-1'>
            <div className='sm:hidden'>
              <Image
                src='/images/home/map_small.png'
                alt='Map of the world'
                layout='fixed'
                width={674}
                height={548}
                quality={100}
              />
            </div>
            <div className='hidden sm:block lg:hidden'>
              <Image
                src='/images/home/map_medium.png'
                alt='Map of the world'
                layout='fixed'
                width={1069}
                height={574}
                quality={100}
              />
            </div>
            <div className='hidden lg:block'>
              <Image
                src='/images/home/map_large.png'
                alt='Map of the world'
                layout='fixed'
                width={1959}
                height={855}
                quality={100}
              />
            </div>
          </div>
          <FlamePulse />
          <div className='hidden sm:block sm:absolute sm:bg-white sm:right-0 sm:h-8 lg:h-16 sm:w-1/12 xl:w-1/6 2xl:w-1/5 sm:z-10' />
          <div className='hidden sm:block sm:absolute sm:bg-white sm:bottom-0 sm:h-8 lg:h-16 sm:w-1/6 xl:w-1/4 2xl:w-1/3 sm:z-10' />
          <div className='w-full pt-56 pb-24 text-left sm:flex sm:flex-col sm:items-center sm:text-center sm:pt-52 sm:pb-32 lg:pb-40'>
            <div className='max-w-xl mx-4 mb-12 sm:max-w-sm md:max-w-2xl lg:max-w-xl lg:mb-60'>
              <p className='mb-3 text-lg font-semibold text-brands-chop'>The Great Commission</p>
              <h3 className='mb-2 text-white section-header'>
                God is working through churches just like yours.
              </h3>
              <p className='text-white body-paragraph'>
                Since 2011, churches around the world have leveraged the Church Online Platform to
                reach the lost in their own communities and in every country on Earth.
              </p>
            </div>
            <div className='h-0' />
            <div className='text-center'>
              <h1 className={`text-white ${styles['salvation-font']} mb-2`}>
                {(totalSalvationCount ?? '').toLocaleString()}
              </h1>
              <p className='text-white body-paragraph'>Indicated salvations since 2011</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center w-full h-auto max-w-6xl mx-auto mb-24 sm:mb-32 lg:mb-40'>
          <div className='mb-12 lg:mb-20'>
            <Image
              src={'/images/home/glyph_quote.png'}
              alt=''
              layout='fixed'
              width={72}
              height={72}
              quality={100}
            />
          </div>
          <div className='flex flex-col items-center w-screen'>
            <div className='sm:hidden'>
              <Image
                src='/images/home/quote_wall_small.png'
                alt='Wall of quotes about the Church Online Platform'
                layout='fixed'
                width={343}
                height={767}
                quality={100}
              />
            </div>
            <div className='hidden sm:block lg:hidden'>
              <Image
                src='/images/home/quote_wall_medium.png'
                alt='Wall of quotes about the Church Online Platform'
                layout='fixed'
                width={1024}
                height={641}
                quality={100}
              />
            </div>
            <div className='hidden lg:block'>
              <Image
                src='/images/home/quote_wall_large.png'
                alt='Wall of quotes about the Church Online Platform'
                layout='fixed'
                width={1204}
                height={615}
                quality={100}
              />
            </div>
          </div>
          <div className='mt-16'>
            <AngleButton text={'See everything the Platform can do'} link={'/features'} />
          </div>
        </div>
      </main>
    </>
  );
};

export default Index;
