import { FC } from 'react';
import styles from './OrangeDivider.module.css';

interface OrangeDividerProps {
  className?: string;
}

const OrangeDivider: FC<OrangeDividerProps> = ({ className }) => {
  const defaultClassName = `rounded ${styles['gradient']} ${styles['orange-shadow']}`;

  return <div className={`${defaultClassName} ${className}`} />;
};

export default OrangeDivider;
