import { FC } from 'react';
import TableRow from './TableRow';
import styles from './FeaturesTable.module.css';

const items = [
  { key: 'feature 1', feature: 'Customer support' },
  {
    key: 'feature 2',
    feature: 'Live or Simulated Live Services',
  },
  { key: 'feature 3', feature: 'Unlimited services & attenders' },
  { key: 'feature 4', feature: 'Unlimited admins and hosts' },
  {
    key: 'feature 5',
    feature: 'Unlimited public chat, private chat, and prayers',
  },
  {
    key: 'feature 6',
    feature: 'Chat controls like mute, delete, and direct chat',
  },
  { key: 'feature 7', feature: 'Customizable calls to action' },
  { key: 'feature 8', feature: 'Integrations like Google Analytics & Facebook' },
  {
    key: 'feature 9',
    feature: 'Private chat and resources for staff and volunteers',
  },
  { key: 'feature 10', feature: 'Custom domain name' },
  { key: 'feature 11', feature: 'Analytics dashboards' },
  { key: 'feature 12', feature: 'Future feature releases and updates' },
];

const FeaturesTable: FC = () => {
  return (
    <div className='-ml-4 sm:w-1/2 sm:mr-4'>
      <div className='flex w-full h-4'>
        <div className='w-full ml-4 sm:mr-4'>
          <div className='flex flex-row w-full h-full'>
            <div className='w-full h-full' />
            <div className={`${styles['gradient']} rounded-t ${styles['table-width']} h-full`} />
          </div>
        </div>
      </div>

      <div className='flex flex-row w-screen py-2 bg-gray-5 sm:w-full h-3/4 sm:h-full sm:pt-2 sm:-mb-8'>
        <div className='w-full ml-4 mr-4'>
          <div className='flex flex-row w-full'>
            <div className='w-full'>
              <p className='my-2 text-gray-80'>FEATURE</p>
              <div className='w-full h-px bg-gray-30' />
            </div>
            <div className={`relative ${styles['gradient']} ${styles['table-width']} -mt-2`}>
              <p className='mt-1 text-lg font-bold text-center text-white'>Free</p>
              <div className='absolute bottom-0 w-full h-px bg-white-transparent-30' />
            </div>
          </div>
          <ul className='mt-0 font-semibold'>
            {items.map((item, index) => {
              return (
                <TableRow
                  key={item.key}
                  feature={item.feature}
                  isLastRow={index === items.length - 1}
                />
              );
            })}
          </ul>
        </div>
      </div>

      <div className='w-full h-4 sm:flex'>
        <div className='ml-4 sm:mr-4 sm:w-full'>
          <div className='flex flex-row w-full h-full'>
            <div className='w-full h-full' />
            <div
              className={`${styles['gradient']} rounded-b h-6 ${styles['table-width']} -mt-2 sm:mt-6 z-10 ${styles['orange-shadow']}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesTable;
