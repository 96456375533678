import { FC } from 'react';
import Image from 'next/image';
import styles from './TableRow.module.css';

interface TableRowProps {
  feature: string;
  isLastRow: boolean;
}

const TableRow: FC<TableRowProps> = ({ feature, isLastRow }) => {
  return (
    <div className='flex flex-row w-full'>
      <div className='w-full'>
        <p className='my-2 mr-2 leading-tight text-gray-100 lg:leading-snug'>{feature}</p>
        {!isLastRow && <div className='w-full h-px bg-gray-30' />}
      </div>
      <div className={`relative ${styles['gradient']} ${styles['table-width']}`}>
        <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-0.5'>
          <Image src={'/images/home/check_mark.png'} alt='' width={20} height={20} />
        </div>
        {!isLastRow && <div className='absolute bottom-0 w-full h-px bg-white-transparent-30' />}
      </div>
    </div>
  );
};

export default TableRow;
