import { FC } from 'react';
import Image from 'next/image';

const items = [
  { key: 'YouTube', src: '/images/home/provider_youtube.png', width: '90', height: '32' },
  { key: 'Facebook', src: '/images/home/provider_facebook_live.png', width: '60', height: '32' },
  { key: 'Vimeo', src: '/images/home/provider_vimeo.png', width: '79', height: '32' },
  { key: 'Resi', src: '/images/home/provider_resi.png', width: '64', height: '32' },
  { key: 'Boxcast', src: '/images/home/provider_boxcast.png', width: '121', height: '32' },
];

const LogoGrid: FC = () => {
  return (
    <>
      <div className='flex flex-wrap justify-around w-full h-1/4'>
        {items.map(item => {
          return (
            <div key={item.key} className='flex justify-center h-8 w-36 xl:w-auto xl:mx-6 mb-9'>
              <Image
                src={item.src}
                alt={item.key + ' Logo'}
                layout='fixed'
                width={item.width}
                height={item.height}
                quality={100}
              />
            </div>
          );
        })}
        <div className='flex items-center justify-center h-8 w-36 mb-9'>
          <p className='text-lg font-semibold text-gray-50'>And More</p>
        </div>
      </div>
    </>
  );
};

export default LogoGrid;
