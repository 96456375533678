import { FC } from 'react';
import styles from './flamepulse.module.css';

const FlamePulse: FC = () => {
  return (
    <>
      <div className='relative w-1/2 h-full left-1/2'>
        <div className='absolute z-10 top-12 lg:top-24 -left-40 sm:-left-16'>
          <figure className={styles['flame-pulse-1']} />
        </div>
        <div className='absolute z-10 top-24 sm:top-32 lg:top-48 left-16 sm:left-40 lg:left-72'>
          <figure className={styles['flame-pulse-2']} />
        </div>
        <div
          className={`hidden sm:block md:hidden lg:block absolute top-80 ${styles['flame-pulse-3-position']} -left-72 lg:-left-96`}
        >
          <figure className={styles['flame-pulse-3']} />
        </div>
        <div
          className={`hidden md:block absolute top-16 lg:top-36 -left-96 ${styles['flame-pulse-4-position']}`}
        >
          <figure className={styles['flame-pulse-4']} />
        </div>
      </div>
    </>
  );
};

export default FlamePulse;
